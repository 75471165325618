import { ChangeEvent, forwardRef, ForwardRefRenderFunction } from 'react';
import { GlobalStyles, Theme } from '@mui/material';
import { alpha } from '@/theme/utils';
import { SearchControl } from 'ui-kit';
import { Interpolation } from '@emotion/react';

const _GoogleAutocomplete: ForwardRefRenderFunction<
  HTMLInputElement | null,
  {
    error?: string;
    placeholder?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  }
> = ({ error, value, placeholder, onChange }, ref) => {
  return (
    <>
      <GlobalStyles styles={dropdownStyles} />
      <SearchControl
        simpleTextInput
        uncontrolled
        error={error}
        inputRef={ref}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
export const AutocompleteInputView = forwardRef(_GoogleAutocomplete);

const dropdownStyles: Interpolation<Theme> = (t) => ({
  '.pac-item': {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    span: {
      fontFamily: 'inherit',
      fontSize: '14px',
      color: 'inherit',
    },
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 7),
      color: t.palette.secondary.main,
      span: {
        color: 'inherit',
      },
    },
  },
  '.pac-container': {
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    fontFamily: 'inherit',
    zIndex: 3000,
  },
});
