import React, { FC } from 'react';
import { TextareaControl, TextareaControlProps } from 'ui-kit';
import { FastFieldProps, Field } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';

export const TextAreaControlF: FC<
  Omit<TextareaControlProps, 'name'> & { name: string }
> = (props) => {
  return (
    <Field {...props}>
      {({ field, form, meta }: FastFieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return <TextareaControl {...props} {...field} error={e} />;
      }}
    </Field>
  );
};
