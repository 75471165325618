import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Scrollbar } from '@/components/Scrollbar';
import { useBreakpoints } from 'ui-kit';

export const ScrollbarContainer: FC<PropsWithChildren> = ({ children }) => {
  const { lg } = useBreakpoints();

  if (!lg) {
    return (
      <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {children}
      </Box>
    );
  }
  return <Scrollbar width={4}>{children}</Scrollbar>;
};
