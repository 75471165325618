import { useField } from 'formik';

export const getErrorForDisplay = ({
  error,
  touched,
  value,
  submitCount,
}: {
  error: string | undefined;
  touched: boolean;
  value: unknown;
  submitCount: number;
}) => {
  return error && touched
    ? value
      ? error // show error if error exist and field is not empty
      : submitCount // else, even if field is empty but submit count already reached 1 or more - show anyway
      ? error
      : undefined
    : undefined;
};

export const useFormikError = (fieldName: string, submitCount: number) => {
  const [_, { error, touched, value }] = useField(fieldName);
  return getErrorForDisplay({ error, value, touched, submitCount });
};
