import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useGoogleLoader } from '../GoogleLoaderProvider';
import { getFormattedAddress } from '../shared/utils/getFormattedAddressString';
import { redDefaultMarker } from '@/components/Google/Map/b64Images';

interface MapSettings extends google.maps.MapOptions {
  googlePlace?: google.maps.places.PlaceResult;
  onChange?: (placeId: string) => void;
  hideTooltip?: boolean;
  marker?: string; // base64 icon uri
}
// US country coords
const defaultCoords = { lat: 39.8097343, lng: -98.5556199 };
const defaultMapSettings: MapSettings = {
  center: defaultCoords,
  zoom: 4,
};
export const useGoogleMap = ({
  zoom = defaultMapSettings.zoom,
  center = defaultMapSettings.center,
  googlePlace,
  hideTooltip,
  marker: markerImage,
}: MapSettings = defaultMapSettings) => {
  const ref = useRef<HTMLElement | null>(null);
  const refInfoWindow = useRef<HTMLDivElement>(null);
  const loader = useGoogleLoader();
  const [state, setState] = useState<{
    map?: google.maps.Map | undefined;
    infoWindow?: google.maps.InfoWindow | undefined;
    marker?: google.maps.Marker | undefined;
  }>({});
  const [tooltip, setTooltipInfo] = useState({ header: '', info: '' });

  useEffect(() => {
    let map: google.maps.Map;
    void loader?.importLibrary('maps').then(({ Map, InfoWindow }) => {
      if (!ref.current) {
        return;
      }
      map = new Map(ref.current, {
        center: { lat: center?.lat as number, lng: center?.lng as number },
        streetViewControl: false,
        mapTypeControl: false,
        zoom,
      });
      map.addListener('click', async (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          try {
            // todo
          } catch {}
        }
      });
      const marker = new google.maps.Marker({
        map,
        icon: markerImage ?? redDefaultMarker,
        anchorPoint: new google.maps.Point(0, -29),
      });
      marker.setVisible(false);

      let infoWindow: google.maps.InfoWindow | undefined;
      if (refInfoWindow.current) {
        infoWindow = new InfoWindow();
        infoWindow.setContent(refInfoWindow.current);
      }
      marker.addListener('click', () => {
        infoWindow?.open(map, marker);
        if (refInfoWindow.current)
          refInfoWindow.current.style.display = 'block';
      });

      setState({ map, infoWindow, marker });
    });
    return () => {
      setState({});
      if (map) {
        map.unbindAll();
        google.maps.event.clearInstanceListeners(map);
      }
    };
  }, [center?.lat, center?.lng, loader, markerImage, zoom]);
  const setMapPlace = useCallback(
    (place: google.maps.places.PlaceResult) => {
      state.infoWindow?.close();
      state.marker?.setVisible(false);

      if (!place.geometry?.location) {
        console.error("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        state.map?.fitBounds(place.geometry.viewport);
      } else {
        state.map?.setCenter(place.geometry.location);
        state.map?.setZoom(17);
      }
      state.marker?.setPosition(place.geometry.location);
      state.marker?.setVisible(true);
      if (!hideTooltip) {
        state.infoWindow?.open(state.map, state.marker);
        if (refInfoWindow.current) {
          refInfoWindow.current.style.display = 'block';
        }
      }
    },
    [hideTooltip, state.infoWindow, state.map, state.marker]
  );

  const setMapPlaceRef = useRef(setMapPlace);
  setMapPlaceRef.current = setMapPlace;

  useLayoutEffect(() => {
    if (googlePlace && state.map) {
      setMapPlaceRef.current(googlePlace);
      const { formattedAddress, header } =
        getFormattedAddress(googlePlace) ?? '';
      setTooltipInfo({
        info: formattedAddress,
        header,
      });
    }
  }, [state.map, googlePlace]);

  const reset = () => {
    state.map?.setCenter(defaultCoords);
    state.map?.setZoom(4);
    state.infoWindow?.close();
    state.marker?.setVisible(false);
  };

  return {
    mapRef: ref,
    tooltipRef: refInfoWindow,
    tooltip,
    setMapPlace,
    reset,
    ...state,
  };
};
