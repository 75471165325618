import { Tab, TabPanel, Tabs, useRouteTabs } from '@/components/TabPanel';
import React, { useMemo } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { BackButton } from '@/features/company-details/BackButton';
import { Stack } from '@mui/material';
import { useBreakpoints } from 'ui-kit';
import { Password } from './Password';
import { Authentication } from './Authentication';
import { AddNewDialog } from '@/features/profile/Security/AddNewDialog';
import { EditDialog } from '@/features/profile/Security/EditDialog';

enum TabId {
  Password = 'password',
  Authentication = 'authentication',
}
const useTabsContent = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return [
      { title: t('PASSWORD'), id: TabId.Password, Component: Password },
      {
        title: t('AUTHENTICATION'),
        id: TabId.Authentication,
        Component: Authentication,
      },
    ];
  }, [t]);
};
export const SecurityPage = () => {
  const d = useRouteTabs('');
  const tabs = useTabsContent();
  const { md } = useBreakpoints();
  return (
    <>
      <Tabs
        value={d.path}
        onChange={d.handleChange}
        enableMobileView
        variant={'standard'}
        defaultTab={TabId.Password}
        sx={{ button: { minWidth: { md: 250 } } }}
      >
        {tabs.map((tab) => {
          return <Tab label={tab.title} value={tab.id} key={tab.id} />;
        })}
      </Tabs>
      <Stack sx={{ flexGrow: 1, minHeight: 100 }}>
        {!md ? (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} index={d.path} key={id}>
                  <BackButton onClick={() => d.handleChange(null, '')} />
                  <Component />
                </TabPanel>
              );
            })}
          </>
        ) : (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} index={d.path} key={id}>
                  <Component />
                </TabPanel>
              );
            })}
          </>
        )}
      </Stack>
      <Modals />
    </>
  );
};

const Modals = () => {
  return (
    <>
      <AddNewDialog />
      <EditDialog />
    </>
  );
};
