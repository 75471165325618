import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { GoogleMap } from '@/components/Google/Map/GoogleMap';
import { GoogleAutocomplete } from '@/components/Google/Autocomplete/GoogleAutocomplete';
import { useGeocoder } from '../shared/useGeocoder';
import { getFullAddress, PlaceResult } from '../shared/utils/getFullAddress';
import { FullAddress } from '../shared/types/FullAddress';

export const AddressLookup: FC<{
  sx?: SxProps<Theme>;
  onChange?: (addr: FullAddress | null) => void;
  error?: string;
  initialValue?: { addressString?: string; googlePlaceId?: string }; // placeId
  readonly?: boolean;
  placeholder: string;
  name?: string;
}> = ({ placeholder, initialValue, readonly, sx, onChange, error }) => {
  const [place, setMapPlace] = useState<PlaceResult | undefined>();
  const [value, setValue] = useState<null | FullAddress>(null);
  const resetHandlerRef = useRef({ reset: () => {} });
  const getGeocoderResult = useGeocoder();

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const handleAutocomplete = useCallback(
    (result: PlaceResult | null, setMap: boolean = true) => {
      const fullAddress = result ? getFullAddress(result) : null;
      onChangeRef.current?.(fullAddress);
      setValue(fullAddress);
      if (!result) resetHandlerRef.current.reset();
      if (setMap && result) setMapPlace(result);
    },
    []
  );

  const handleAutocompleteRef = useRef(handleAutocomplete);
  handleAutocompleteRef.current = handleAutocomplete;
  useEffect(() => {
    const hasInitialValue =
      initialValue?.googlePlaceId || initialValue?.addressString;
    const hasHandler = !!getGeocoderResult;
    if (hasInitialValue && hasHandler) {
      void getGeocoderResult({
        placeId: initialValue.googlePlaceId,
        address: initialValue.googlePlaceId
          ? undefined
          : initialValue.addressString,
      }).then((result) => {
        const placeResult = result as google.maps.places.PlaceResult;
        if (!initialValue.googlePlaceId && initialValue.addressString) {
          handleAutocompleteRef.current(placeResult, false);
        }
        setMapPlace(placeResult);
        setValue(getFullAddress(placeResult));
      });
    }
  }, [
    getGeocoderResult,
    initialValue?.addressString,
    initialValue?.googlePlaceId,
  ]);

  return (
    <>
      <Stack sx={sx}>
        {!readonly && (
          <Box sx={{ pb: { xs: 24 } }}>
            <GoogleAutocomplete
              onChange={handleAutocomplete}
              error={error}
              placeholder={placeholder}
              value={value?.fullAddress ?? initialValue?.addressString}
            />
          </Box>
        )}
        <GoogleMap place={place} innerRef={resetHandlerRef} />
      </Stack>
    </>
  );
};
