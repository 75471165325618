import { FC, useEffect } from 'react';
import { useGoogleAutocomplete } from '@/components/Google/Autocomplete/useGoogleAutocomplete';
import { AutocompleteInputView } from '@/components/Google/Autocomplete/AutocompleteInputView';

export const GoogleAutocomplete: FC<{
  onChange?: (placeResult: google.maps.places.PlaceResult | null) => void;
  placeholder?: string;
  placeId?: string;
  error?: string;
  value?: string;
}> = ({ onChange, placeId, placeholder, error, value }) => {
  const { ref } = useGoogleAutocomplete({
    onChange,
  });
  useEffect(() => {
    if (value && ref.current) {
      ref.current.value = value;
    }
  }, [ref, value]);

  return (
    <AutocompleteInputView
      ref={ref}
      error={error}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        if (!e.target.value) {
          onChange?.(null);
        }
      }}
    />
  );
};
