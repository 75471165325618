import { styled, css as _, Box } from '@mui/material';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { FC, forwardRef, useCallback, useEffect, useState } from 'react';

const Scrollbar_ = forwardRef<
  PerfectScrollbar,
  ScrollBarProps & { shadow?: boolean }
>(({ children, shadow, ...props }, ref) => {
  const [scroll, setScroll] = useState({ x: 0, y: 0, top: 0, bottom: 0 });
  const [containerEl, setContainerEl] = useState<HTMLElement | null>(null);

  const handleScrollDownNdUp = useCallback((e: HTMLElement) => {
    setScroll({
      x: e.scrollLeft,
      y: e.scrollTop,
      top: e.scrollTop,
      bottom: e.scrollHeight - e.offsetHeight - e.scrollTop,
    });
  }, []);

  useEffect(() => {
    if (!containerEl) return;
    handleScrollDownNdUp(containerEl);
    const obs = new ResizeObserver(() => handleScrollDownNdUp(containerEl));
    obs.observe(containerEl);

    return () => obs.disconnect();
    // ignore handleScrollDownNdUp
    // eslint-disable-next-line
  }, [containerEl]);

  return (
    <PerfectScrollbar
      {...props}
      ref={ref}
      containerRef={(el) => {
        setContainerEl(el);
        props.containerRef?.(el);
      }}
      onScrollDown={handleScrollDownNdUp}
      onScrollUp={handleScrollDownNdUp}
      style={{ position: 'relative', ...props.style }}
    >
      {shadow && <ShadowTop show={scroll.top < 10} />}
      {children}
      {shadow && <ShadowBottom show={scroll.bottom < 10} />}
    </PerfectScrollbar>
  );
});
Scrollbar_.displayName = 'Scrollbar';
export const ShadowTop: FC<{ show?: boolean }> = ({ show }) => {
  return (
    <Box
      className={'shadow-top'}
      sx={[
        styles.shadow,
        {
          opacity: show ? 0 : 1,
          top: 0,
          boxShadow: '0 -12px 17px 5px #00000054',
        },
      ]}
    />
  );
};
export const ShadowBottom: FC<{ show?: boolean }> = ({ show }) => {
  return (
    <Box
      className={'shadow-bottom'}
      sx={[
        styles.shadow,
        {
          opacity: show ? 0 : 1,
          bottom: 0,
          boxShadow: '0 12px 17px 5px #00000054',
        },
      ]}
    />
  );
};
export const Scrollbar = styled(Scrollbar_)<{
  width?: number;
  permanent?: boolean;
}>(
  ({ theme: t, width, permanent = true }) => _`
  .ps__thumb-x, .ps__thumb-y {
    background-color: ${t.palette.secondary._14}!important; 
    border-radius: 0!important;
    width: ${width ?? 10}px;
  } 
  .ps__thumb-x {
    bottom: 0
  }
  .ps__thumb-y {
    right: 0
  }
  .ps__rail-x, .ps__rail-y {
    background-color: transparent!important;
    width: ${width ?? 10}px;
    z-index: 1;
  } 
  .ps__rail-y {
    opacity: ${permanent ? 1 : 'ww'}
  }
  `
);
Scrollbar.defaultProps = {
  options: { minScrollbarLength: 130 },
};

const styles = {
  shadow: {
    position: 'sticky',
    height: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    transition: 'all .3',
  },
};
