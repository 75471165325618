/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextareaControl, TextareaControlProps } from 'ui-kit';

export const EditorControlF: FC<
  Omit<TextareaControlProps, 'name' | 'value'> & {
    name: string;
    value?: string;
    showErrorsAfterSubmit: boolean;
  }
> = (props) => {
  const {
    showErrorsAfterSubmit,
    ref,
    rows,
    size,
    defaultValue,
    max,
    ...restProps
  } = props;
  const [field, meta] = useField(restProps);
  const f = useFormikContext();
  const error =
    meta.error && meta.touched
      ? showErrorsAfterSubmit
        ? f.submitCount
          ? meta.error
          : undefined
        : meta.value
        ? meta.error
        : f.submitCount
        ? meta.error
        : undefined
      : undefined;
  return (
    <TextareaControl
      {...{
        ref,
        rows,
        size,
        defaultValue,
        max,
      }}
      {...field}
      {...props}
      error={error}
    />
  );
};
